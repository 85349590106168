/*! 2.2.2 */ ! function() {
    function a(a, b) {
        window.XMLHttpRequest.prototype[a] = b(window.XMLHttpRequest.prototype[a])
    }

    function b(a, b, c) {
        try {
            Object.defineProperty(a, b, {
                get: c
            })
        } catch (d) {}
    }
    var c = function() {
        try {
            var a = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
            if (a) return !0
        } catch (b) {
            if (void 0 != navigator.mimeTypes["application/x-shockwave-flash"]) return !0
        }
        return !1
    };
    if (window.XMLHttpRequest && !window.FormData || window.FileAPI && FileAPI.forceLoad) {
        var d = function(a) {
            if (!a.__listeners) {
                a.upload || (a.upload = {}), a.__listeners = [];
                var b = a.upload.addEventListener;
                a.upload.addEventListener = function(c, d) {
                    a.__listeners[c] = d, b && b.apply(this, arguments)
                }
            }
        };
        a("open", function(a) {
            return function(b, c, e) {
                d(this), this.__url = c;
                try {
                    a.apply(this, [b, c, e])
                } catch (f) {
                    f.message.indexOf("Access is denied") > -1 && a.apply(this, [b, "_fix_for_ie_crossdomain__", e])
                }
            }
        }), a("getResponseHeader", function(a) {
            return function(b) {
                return this.__fileApiXHR && this.__fileApiXHR.getResponseHeader ? this.__fileApiXHR.getResponseHeader(b) : null == a ? null : a.apply(this, [b])
            }
        }), a("getAllResponseHeaders", function(a) {
            return function() {
                return this.__fileApiXHR && this.__fileApiXHR.getAllResponseHeaders ? this.__fileApiXHR.getAllResponseHeaders() : null == a ? null : a.apply(this)
            }
        }), a("abort", function(a) {
            return function() {
                return this.__fileApiXHR && this.__fileApiXHR.abort ? this.__fileApiXHR.abort() : null == a ? null : a.apply(this)
            }
        }), a("setRequestHeader", function(a) {
            return function(b, c) {
                if ("__setXHR_" === b) {
                    d(this);
                    var e = c(this);
                    e instanceof Function && e(this)
                } else this.__requestHeaders = this.__requestHeaders || {}, this.__requestHeaders[b] = c, a.apply(this, arguments)
            }
        }), a("send", function(a) {
            return function() {
                var d = this;
                if (arguments[0] && arguments[0].__isFileAPIShim) {
                    var e = arguments[0],
                        f = {
                            url: d.__url,
                            jsonp: !1,
                            cache: !0,
                            complete: function(a, c) {
                                d.__completed = !0, !a && d.__listeners.load && d.__listeners.load({
                                    type: "load",
                                    loaded: d.__loaded,
                                    total: d.__total,
                                    target: d,
                                    lengthComputable: !0
                                }), !a && d.__listeners.loadend && d.__listeners.loadend({
                                    type: "loadend",
                                    loaded: d.__loaded,
                                    total: d.__total,
                                    target: d,
                                    lengthComputable: !0
                                }), "abort" === a && d.__listeners.abort && d.__listeners.abort({
                                    type: "abort",
                                    loaded: d.__loaded,
                                    total: d.__total,
                                    target: d,
                                    lengthComputable: !0
                                }), void 0 !== c.status && b(d, "status", function() {
                                    return 0 == c.status && a && "abort" !== a ? 500 : c.status
                                }), void 0 !== c.statusText && b(d, "statusText", function() {
                                    return c.statusText
                                }), b(d, "readyState", function() {
                                    return 4
                                }), void 0 !== c.response && b(d, "response", function() {
                                    return c.response
                                });
                                var e = c.responseText || (a && 0 == c.status && "abort" !== a ? a : void 0);
                                b(d, "responseText", function() {
                                    return e
                                }), b(d, "response", function() {
                                    return e
                                }), a && b(d, "err", function() {
                                    return a
                                }), d.__fileApiXHR = c, d.onreadystatechange && d.onreadystatechange(), d.onload && d.onload()
                            },
                            fileprogress: function(a) {
                                if (a.target = d, d.__listeners.progress && d.__listeners.progress(a), d.__total = a.total, d.__loaded = a.loaded, a.total === a.loaded) {
                                    var b = this;
                                    setTimeout(function() {
                                        d.__completed || (d.getAllResponseHeaders = function() {}, b.complete(null, {
                                            status: 204,
                                            statusText: "No Content"
                                        }))
                                    }, 1e4)
                                }
                            },
                            headers: d.__requestHeaders
                        };
                    f.data = {}, f.files = {};
                    for (var g = 0; g < e.data.length; g++) {
                        var h = e.data[g];
                        null != h.val && null != h.val.name && null != h.val.size && null != h.val.type ? f.files[h.key] = h.val : f.data[h.key] = h.val
                    }
                    setTimeout(function() {
                        if (!c()) throw 'Adode Flash Player need to be installed. To check ahead use "FileAPI.hasFlash"';
                        d.__fileApiXHR = FileAPI.upload(f)
                    }, 1)
                } else a.apply(d, arguments)
            }
        }), window.XMLHttpRequest.__isFileAPIShim = !0;
        var e = function(a) {
                if (!c()) throw 'Adode Flash Player need to be installed. To check ahead use "FileAPI.hasFlash"';
                var b = angular.element(a);
                if (!(b.attr("disabled") || b.hasClass("js-fileapi-wrapper") || null == b.attr("ng-file-select") && null == b.attr("data-ng-file-select") && null == b.attr("ng-file-generated-elem--") || (b.addClass("js-fileapi-wrapper"), null == b.attr("ng-file-generated-elem--")))) {
                    var d = angular.element(document.getElementById("e" + b.attr("id")));
                    d.bind("mouseover", function() {
                        ("" === b.parent().css("position") || "static" === b.parent().css("position")) && b.parent().css("position", "relative"), b.css("position", "absolute").css("top", d[0].offsetTop + "px").css("left", d[0].offsetLeft + "px").css("width", d[0].offsetWidth + "px").css("height", d[0].offsetHeight + "px").css("padding", d.css("padding")).css("margin", d.css("margin")).css("filter", "alpha(opacity=0)"), d.attr("onclick", ""), b.css("z-index", "1000")
                    })
                }
            },
            f = function(a) {
                return function(b) {
                    for (var c = FileAPI.getFiles(b), d = 0; d < c.length; d++) void 0 === c[d].size && (c[d].size = 0), void 0 === c[d].name && (c[d].name = "file"), void 0 === c[d].type && (c[d].type = "undefined");
                    b.target || (b.target = {}), b.target.files = c, b.target.files != c && (b.__files_ = c), (b.__files_ || b.target.files).item = function(a) {
                        return (b.__files_ || b.target.files)[a] || null
                    }, a && a.apply(this, [b])
                }
            },
            g = function(a, b) {
                return ("change" === b.toLowerCase() || "onchange" === b.toLowerCase()) && "file" == a.getAttribute("type")
            };
        HTMLInputElement.prototype.addEventListener && (HTMLInputElement.prototype.addEventListener = function(a) {
                return function(b, c, d, h) {
                    g(this, b) ? (e(this), a.apply(this, [b, f(c), d, h])) : a.apply(this, [b, c, d, h])
                }
            }(HTMLInputElement.prototype.addEventListener)), HTMLInputElement.prototype.attachEvent && (HTMLInputElement.prototype.attachEvent = function(a) {
                return function(b, c) {
                    g(this, b) ? (e(this), window.jQuery ? angular.element(this).bind("change", f(null)) : a.apply(this, [b, f(c)])) : a.apply(this, [b, c])
                }
            }(HTMLInputElement.prototype.attachEvent)), window.FormData = FormData = function() {
                return {
                    append: function(a, b, c) {
                        b.__isFileAPIBlobShim && (b = b.data[0]), this.data.push({
                            key: a,
                            val: b,
                            name: c
                        })
                    },
                    data: [],
                    __isFileAPIShim: !0
                }
            }, window.Blob = Blob = function(a) {
                return {
                    data: a,
                    __isFileAPIBlobShim: !0
                }
            },
            function() {
                if (window.FileAPI || (window.FileAPI = {}), FileAPI.forceLoad && (FileAPI.html5 = !1), !FileAPI.upload) {
                    var a, b, d, e, f, g = document.createElement("script"),
                        h = document.getElementsByTagName("script");
                    if (window.FileAPI.jsUrl) a = window.FileAPI.jsUrl;
                    else if (window.FileAPI.jsPath) b = window.FileAPI.jsPath;
                    else
                        for (d = 0; d < h.length; d++)
                            if (f = h[d].src, e = f.search(/\/angular\-file\-upload[\-a-zA-z0-9\.]*\.js/), e > -1) {
                                b = f.substring(0, e + 1);
                                break
                            }
                    null == FileAPI.staticPath && (FileAPI.staticPath = b), g.setAttribute("src", a || b + "FileAPI.min.js"), document.getElementsByTagName("head")[0].appendChild(g), FileAPI.hasFlash = c()
                }
            }(), FileAPI.disableFileInput = function(a, b) {
                b ? a.removeClass("js-fileapi-wrapper") : a.addClass("js-fileapi-wrapper")
            }
    }
    window.FileReader || (window.FileReader = function() {
        var a = this,
            b = !1;
        this.listeners = {}, this.addEventListener = function(b, c) {
            a.listeners[b] = a.listeners[b] || [], a.listeners[b].push(c)
        }, this.removeEventListener = function(b, c) {
            a.listeners[b] && a.listeners[b].splice(a.listeners[b].indexOf(c), 1)
        }, this.dispatchEvent = function(b) {
            var c = a.listeners[b.type];
            if (c)
                for (var d = 0; d < c.length; d++) c[d].call(a, b)
        }, this.onabort = this.onerror = this.onload = this.onloadstart = this.onloadend = this.onprogress = null;
        var c = function(b, c) {
                var d = {
                    type: b,
                    target: a,
                    loaded: c.loaded,
                    total: c.total,
                    error: c.error
                };
                return null != c.result && (d.target.result = c.result), d
            },
            d = function(d) {
                if (b || (b = !0, a.onloadstart && this.onloadstart(c("loadstart", d))), "load" === d.type) {
                    a.onloadend && a.onloadend(c("loadend", d));
                    var e = c("load", d);
                    a.onload && a.onload(e), a.dispatchEvent(e)
                } else if ("progress" === d.type) {
                    var e = c("progress", d);
                    a.onprogress && a.onprogress(e), a.dispatchEvent(e)
                } else {
                    var e = c("error", d);
                    a.onerror && a.onerror(e), a.dispatchEvent(e)
                }
            };
        this.readAsArrayBuffer = function(a) {
            FileAPI.readAsBinaryString(a, d)
        }, this.readAsBinaryString = function(a) {
            FileAPI.readAsBinaryString(a, d)
        }, this.readAsDataURL = function(a) {
            FileAPI.readAsDataURL(a, d)
        }, this.readAsText = function(a) {
            FileAPI.readAsText(a, d)
        }
    })
}();